<template>
  <div>
    <q-form ref="editForm">
      <c-card title="수급업체 평가 기본정보" class="cardClassDetailForm">
        <template slot="card-description">
          <q-chip outline square v-if="evaluationData.evaluationGradeName" dense color="blue-grey-3" style="vertical-align: baseline;">
            평가등급<q-space />
            <q-chip outline square dense :color="evaluationGradeInfo.attrVal3" text-color="white">
              {{evaluationData.evaluationGradeName}}
            </q-chip>
            <q-tooltip>{{ evaluationGradeInfo.description }}</q-tooltip>
          </q-chip>
        </template>
        <template slot="card-detail">
          <div class="col-2">
            <c-plant
              :required="true"
              :disabled="disabled||isOld"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="evaluationData.plantCd"
              @datachange="getResultItem" />
          </div>
          <div class="col-2">
            <c-vendor
              :disabled="disabled"
              :editable="editable"
              :required="true"
              label="평가업체"
              name="vendorCd"
              v-model="evaluationData.vendorCd">
            </c-vendor>
          </div>
          <div class="col-2">
            <c-select
              :required="true" 
              :disabled="disabled"
              :editable="editable"
              codeGroupCd="VENDOR_EVALUATION_KIND_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="vendorEvaluationKindCd"
              label="평가구분"
              v-model="evaluationData.vendorEvaluationKindCd"
            ></c-select>
          </div>
          <div class="col-2">
            <c-datepicker
              :required="true"
              :disabled="disabled"
              :editable="editable"
              :range="true"
              label="평가기간"
              name="period"
              v-model="evaluationData.evaluationPeriod">
            </c-datepicker>
          </div>
          <div class="col-1">
            <c-datepicker
              :required="true" 
              :disabled="disabled"
              :editable="editable"
              type="year"
              label="평가년도"
              name="evaluationYear"
              v-model="evaluationData.evaluationYear"
            />
          </div>
          <div class="col-3">
            <!-- 관련공사 -->
            <c-construnction
              :disabled="disabled"
              :editable="editable"
              name="sopConstructionId"
              v-model="evaluationData.sopConstructionId">
            </c-construnction>
          </div>
          <div class="col-2">
            <c-field
              :required="true"
              :disabled="disabled"
              :editable="editable"
              :data="evaluationData"
              :plantCd="evaluationData.plantCd"
              type="dept_user"
              label="평가자"
              name="assessUserId"
              v-model="evaluationData.assessUserId" />
          </div>
          <div class="col-6">
            <c-text 
              :disabled="disabled"
              :editable="editable"
              label="평가결과 종합"
              name="remarks"
              v-model="evaluationData.remarks">
            </c-text>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="table"
      title="평가 항목 합계"
      :editable="editable&&!disabled"
      :columns="gridSubTotal.columns"
      :data="evaluationData.itemResultSubTotals"
      :gridHeightAuto="true"
      :columnSetting="false"
      :usePaging="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
      :isTop="false"
      :hideBottom="true"
    >
      <template slot="description-front-tr" slot-scope="props">
        <template v-if="props.rowIndex === 0">
          <q-tr :props="props" :key="`e_front_${props.rowIndex}`" class="q-virtual-scroll--with-prev bg-blue-5 vendor-assess-item-sub-total-tr">
            <q-td>
              합계
            </q-td>
            <q-td class="text-align-right">
              {{ evaluationData.totalScore }}
            </q-td>
            <q-td class="text-align-right">
              {{ evaluationData.score }}
            </q-td>
          </q-tr>
        </template>
      </template>
    </c-table>
    <c-table
      ref="table"
      title="평가 항목"
      :editable="editable&&!disabled"
      :columns="grid.columns"
      :data="gridItemResults"
      :gridHeight="gridHeight"
      :columnSetting="false"
      :usePaging="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
      :customTrClass="customTrClass"
    >
      <template slot="suffixTitle">
        <font style="font-size:0.8em;font-weight:300;">
          <q-badge 
            v-for="(score, idx) in scoreItems"
            :key="idx"
            :class="score.attrVal1"
            class="q-mr-sm"
            :label="score.codeName" />
        </font>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="!props.row.subtotal">
          <c-number-column
            :editable="editable"
            :disabled="disabled || !editable"
            :col="col"
            :props="props"
            :colorClass="checkColorClass(props.row)"
            v-model="props.row[col.name]"
            @datachange="datachange(props, col)"
          />
        </template>
        <template v-else>
          {{props.row[col.name]}}
        </template>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'vendor-assess-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmVendorEvaluationId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      evaluationData: {
        mdmVendorEvaluationId: '',  // 업체평가 일련번호
        vendorCd: null,  // 업체코드
        plantCd: null,  // 사업장코드
        vendorEvaluationKindCd: null,  // 수급업체 평가구분
        evaluationStartDate: '',  // 평가시작일
        evaluationEndDate: '',  // 평가종료일
        evaluationPeriod: [],
        evaluationYear: '',  // 평가 년도
        score: null,  // 득점
        totalScore: null,  // 총점
        evaluationGradeCd: null,  // 평가등급
        evaluationGradeName: '',
        remarks: '',  // 비고
        assessUserId: '',
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        sopConstructionId: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        itemResults: [],
        itemResultSubTotals: [],
      },
      gradeItems: [],
      scoreItems: [],
      grid: {
        columns: [
          {
            name: "itemNm",
            field: "itemNm",
            label: "평가항목",
            align: "left",
            sortable: false,
          },
          {
            name: "scoring",
            field: "scoring",
            label: "배점",
            style: 'width:80px', 
            align: "right",
            sortable: false,
          },
          {
            name: "evaluationScore",
            field: "evaluationScore",
            label: "득점",
            style: 'width:80px', 
            type: "custom",
            align: "center",
            setHeader: true,
            sortable: false,
          },
          {
            name: "remarks",
            field: "remarks",
            label: "비고",
            align: "left",
            style: 'width:300px', 
            sortable: false,
          },
        ],
        data: [],
      },
      gridSubTotal: {
        columns: [
          {
            name: "vendorEvaluationTypeName",
            field: "vendorEvaluationTypeName",
            label: "구분",
            align: "left",
            sortable: false,
          },
          {
            name: "scoring",
            field: "scoring",
            label: "배점",
            style: 'width:80px', 
            align: "right",
            type: 'cost',
            sortable: false,
          },
          {
            name: "evaluationScore",
            field: "evaluationScore",
            label: "득점",
            style: 'width:80px', 
            align: "right",
            type: 'cost',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      detailUrl: '',
      itemListUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return (this.contentHeight - 450) + 'px'
    },
    isOld() {
      return Boolean(this.popupParam.mdmVendorEvaluationId)
    },
    disabled() {
      return true;
    },
    evaluationGradeInfo() {
      let info = {
        code: '',
        codeName: '',
        attrVal1: '',
        attrVal2: '',
        attrVal3: '',
        description: '',
      };
      if (this.evaluationData.evaluationGradeCd 
        && this.gradeItems && this.gradeItems.length > 0) {
          info = this.$_.find(this.gradeItems, { code: this.evaluationData.evaluationGradeCd })
      }
      return info;
    },
    gridItemResults() {
      let _itemResults = [];
      if (this.evaluationData.itemResults && this.evaluationData.itemResults.length > 0) {
        let subTotals = this.$_.map(this.evaluationData.itemResultSubTotals, item => {
          return this.$_.extend(item, {
            itemNm: item.vendorEvaluationTypeName,
            disable: true,
            subtotal: true,
          })
        })
        this.$_.forEach(subTotals, item => {
          _itemResults.push(item)
          _itemResults = _itemResults.concat(this.$_.filter(this.evaluationData.itemResults, { vendorEvaluationTypeCd: item.vendorEvaluationTypeCd }));
        })
      }
      return _itemResults
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.cim.vendor.evaluation.result.get.url;
      this.itemListUrl = selectConfig.mdm.cim.vendor.evaluation.result.item.url;
      // code setting
      this.$comm.getComboItems('EVALUATION_GRADE_CD').then(_result => {
        this.gradeItems = _result;
      });
      this.$comm.getComboItems('VENDOR_EVALUATION_SCORE_CD').then(_result => {
        this.scoreItems = _result;
      });
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.mdmVendorEvaluationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          _result.data.evaluationPeriod = [_result.data.evaluationStartDate, _result.data.evaluationEndDate]
          this.$_.extend(this.evaluationData, _result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.getResultItem();
      }
    },
    getResultItem() {
      this.$http.url = this.itemListUrl;
      this.$http.param = {
        plantCd: this.evaluationData.plantCd
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        // vendorEvaluationTypeCd 평가항목 구분코드
        if (_result.data.results && _result.data.results.length > 0) {
          this.$set(this.evaluationData, 'itemResults', _result.data.results)
          this.$set(this.evaluationData, 'itemResultSubTotals', _result.data.subTotals)
        } else {
          this.$set(this.evaluationData, 'itemResults', [])
          this.$set(this.evaluationData, 'itemResultSubTotals', [])
        }
      },);
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    checkColorClass(row) {
      let colorClass = '';
      if (row.evaluationScore >= row.scoreGreat) {
        // 우수
        colorClass = this.$_.find(this.scoreItems, { code: 'VESC000001' }).attrVal1;
      } else if (row.evaluationScore < row.scoreGreat && row.evaluationScore >= row.scoreCommonly) {
        // 보통
        colorClass = this.$_.find(this.scoreItems, { code: 'VESC000005' }).attrVal1;
      } else if (row.evaluationScore < row.scoreCommonly && row.evaluationScore >= row.scoreInadequate) {
        // 미흡
        colorClass = this.$_.find(this.scoreItems, { code: 'VESC000010' }).attrVal1;
      }
      return colorClass;
    },
    customTrClass(props) {
      return props.row.subtotal  ? 'bg-blue-5 vendor-assess-item-sub-total-tr' : '';
    },
  }
};
</script>
<style lang="sass">
.vendor-assess-item-sub-total-tr
  td
    color: white !important
    font-size: 0.95rem
    font-weight: 600
  .text-align-right
    text-align: right
</style>